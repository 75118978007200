<template>
    <v-container>
        <center class="text-center">
            <h1 style="font-weight: 500;">Selecciona el plan que mejor se ajuste a ti!</h1>
            <p class="mt-2">2 Meses <b>Gratis</b> si eliges el plan anual.</p>
            <v-row align="center" justify="center"> <v-col cols="auto" class="text-right"> <span>Mensual</span> </v-col>
                <v-col cols="auto"> <v-switch v-model="anual" inset color="#7579E7"></v-switch> </v-col> <v-col
                    cols="auto" class="text-left"> <span>Anual</span> </v-col> </v-row>

            <p style="font-size: x-small;" class="mb-5"><b>Pill ATS</b> es una compañia de <b>ChatPro AI</b>, la orden de pedido y facturas te aparecen a nombre de <b>ChatPro AI</b>.</p>
            <v-row justify="center" align="center">
                <v-card style="border-radius: 15px; height: 405px; width: 370px;">
                    <v-card-title class="ml-2" style="font-size: normal; font-weight: 600;">{{ plans[0].title
                        }}</v-card-title>
                    <h1 v-if="!anual" class="ml-7" style="font-weight: 800; font-size: 40px; text-align: left;">
                        {{ plans[0].price }}<span style="font-size: small; font-weight: lighter;">/mensual</span>
                    </h1>
                    <h1 v-if="anual" class="ml-7" style="font-weight: 800; font-size: 40px; text-align: left;">
                        {{ plans[0].price }}
                    </h1>
                    <v-card-actions>
                        <v-btn @click="subscribePlan(0)" rounded color="black" outlined class="black--text mx-auto"
                            style="width: 100%; max-width: 300px;">Comenzar<v-icon small
                                right>mdi-arrow-right</v-icon></v-btn>
                    </v-card-actions>
                    <v-list-item three-line class="mt-0">
                        <v-list-item-content class="ml-2" style="text-align: left;">
                            <v-list-item-title v-for="(feature, fIndex) in plans[0].features" :key="fIndex"
                                class="mb-2">
                                <v-icon left size="15" color="#25D366">mdi-checkbox-marked-circle-outline</v-icon>
                                <span style="font-size: small;">{{ feature }}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>

                <v-card class="custom-margins"
                    style="border-radius: 15px; height: 560px; width: 370px; border: 2.5px solid #7579e7;">
                    <v-row>
                        <v-col>
                            <v-card-title class="ml-2" style="font-size: normal; font-weight: 600;">{{ plans[1].title
                                }}</v-card-title>
                        </v-col>
                        <v-col>
                            <v-btn x-small depressed rounded color="#7579E7" class="ml-15 mt-5 white--text"><v-icon left
                                    x-small>mdi-star</v-icon>Popular</v-btn>
                        </v-col>
                    </v-row>

                    <h1 v-if="!anual" class="ml-7" style="font-weight: 800; font-size: 40px; text-align: left;">{{
                        plans[1].price }}<span style="font-size: small; font-weight: lighter;">/mensual</span></h1>
                    <h1 v-if="anual" class="ml-7" style="font-weight: 800; font-size: 40px; text-align: left;">
                        $16<span style="font-size: small; font-weight: 450;"> Cobro anual ($190)</span></h1>
                    <v-card-actions>
                        <v-btn @click="subscribePlan(!anual ? 1 : 3)" rounded color="black" class="white--text mx-auto"
                            style="width: 100%; max-width: 300px;">Comenzar prueba Gratis<v-icon small
                                right>mdi-arrow-right</v-icon></v-btn>
                    </v-card-actions>
                    <v-list-item three-line class="">
                        <v-list-item-content class="ml-2" style="text-align: left;">
                            <v-list-item-title v-for="(feature, fIndex) in plans[1].features" :key="fIndex"
                                class="mb-2">
                                <v-icon left size="15" color="#25D366">mdi-checkbox-marked-circle-outline</v-icon>
                                <span style="font-size: small;">{{ feature }}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <p style="font-size: x-small;">Cancela en cualquier momento.</p>
                </v-card>
                <v-card style="border-radius: 15px; height: 580px; width: 370px;">
                    <v-card-title class="ml-2" style="font-size: normal; font-weight: 600;">{{ plans[2].title
                        }}</v-card-title>
                    <h1 v-if="!anual" class="ml-7" style="font-weight: 800; font-size: 40px; text-align: left;">{{
                        plans[2].price
                    }}<span style="font-size: small; font-weight: lighter;">/mensual</span></h1>
                    <h1 v-if="anual" class="ml-7" style="font-weight: 800; font-size: 40px; text-align: left;">
                        $45<span style="font-size: small; font-weight: 450;"> Cobro anual ($550)</span></h1>

                    <v-card-actions>
                        <v-btn @click="subscribePlan(!anual ? 2 : 4)" rounded color="black" outlined class="black--text mx-auto"
                            style="width: 100%; max-width: 300px;">Comenzar prueba Gratis<v-icon small
                                right>mdi-arrow-right</v-icon></v-btn>
                    </v-card-actions>
                    <v-list-item three-line>
                        <v-list-item-content class="ml-2" style="text-align: left;">
                            <v-list-item-title v-for="(feature, fIndex) in plans[2].features" :key="fIndex"
                                class="mb-2">
                                <v-icon left size="15" color="#25D366">mdi-checkbox-marked-circle-outline</v-icon>
                                <span style="font-size: small;">{{ feature }}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <p style="font-size: x-small;">Cancela en cualquier momento.</p>
                </v-card>
            </v-row>
        </center>
    </v-container>
</template>

<script>
/* eslint-disable no-undef */
export default {
    name: 'HomeView',
    components: {},
    data() {
        return {

            anual: false,

            ticksLabels: [
                '2,000',
                '5,000',
                '10,000',
                '40,000',
            ],
            plans: [
                {
                    title: 'Gratis',
                    price: '$0',
                    features: [
                        'Portal de empleo Personalizado',
                        '5 Publicaciones de Empleo activas',
                        '2.000 Aplicantes',
                        'Categorización del aplicante por etapa ',
                        'Almacenamiento Ilimitado*',
                        'Generación de codigos QR',
                        'Envio de emails de rechazo a candidatos',
                    ]
                },
                {
                    title: 'Starter',
                    price: '$19',
                    features: [
                        'Portal de empleo Personalizado',
                        '40 Publicaciones de Empleo activas',
                        '20.000 Aplicantes',
                        'Categorización del aplicante por etapa ',
                        'Almacenamiento Ilimitado*',
                        'Generación de codigos QR',
                        'Reporte Ejecutivo de Aplicantes y trabajos',
                        'Envio de emails de rechazo a candidatos',
                        'Miembros de equipo Ilimitados',
                        'Descripciones de empleo con IA',
                        'Promoción Ilimitada en Google Jobs',
                        'Formulario de preguntas',

                    ]
                },
                {
                    title: 'Premium',
                    price: '$55',
                    features: [
                        'Portal de empleo Personalizado',
                        '∞ Publicaciones de Empleo activas',
                        '∞ Aplicantes',
                        'Categorización del aplicante por etapa ',
                        'Almacenamiento Ilimitado*',
                        'Generación de codigos QR',
                        'Reporte general de Aplicantes y trabajos',
                        'Envio de emails de rechazo a candidatos',
                        'Miembros de equipo Ilimitados',
                        'Descripciones de empleo con IA',
                        'Promoción Ilimitada en Google Jobs',
                        'Formulario de preguntas',
                        'Atención 24/7'
                    ]
                }
            ]
        };
    },
    methods: {
        subscribePlan(plan) {
            if(plan == 0){
                console.log("El usuario seleccionó el plan FREE " + plan);
                this.$emit('variable-plan', plan);
            }
            else if (plan > 3){
                console.log("El usuario seleccionó el plan ANUAL " + plan);
                this.$emit('variable-plan', plan);
            }
            else {
                console.log("El usuario seleccionó el plan MENSUAL " + plan);
                this.$emit('variable-plan', plan);
            }
            
            // this.$router.push("/Register")
        }
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}


.custom-margins {
    margin: 20px;
    /* Ajusta el valor de acuerdo a tu necesidad */

}

@media (max-width: 600px) {

    /* Modifica el ancho según el tamaño del celular */
    .custom-margins {
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
</style>
