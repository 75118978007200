
import Vue from 'vue'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Router from 'vue-router'

//Empresas
import Register from '../views/EmpresasB2B/Register.vue'
import Login from '../views/EmpresasB2B/Login.vue'
import JobsTree from '../views/EmpresasB2B/JobsTree.vue'
import singleJob from '../views/EmpresasB2B/singleJob.vue'
import Application from '../views/EmpresasB2B/Application.vue'
import passwordRecovery from '../views/EmpresasB2B/passwordRecovery.vue'

//others


Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    //Dashboard
    {
      path: "/Dashboard",
      component: () => import('../views/Dashboard/Dashboard.vue'),
      children: [
        // { path: "/Dashboard/Content", component: () => import('../views/Dashboard/Content.vue') },
        { path: "/Dashboard/Account/", component: () => import('../views/Dashboard/Account.vue') },
        { path: "/Dashboard/main", component: () => import('../views/Dashboard/main.vue') },
        { path: "/Dashboard/customize/", component: () => import('../views/Dashboard/myPage.vue')},
        { path: "/Dashboard/edit/:id_company/:id_job", component: () => import('../views/Dashboard/editJob.vue')},
        { path: "/Dashboard/opportunity/:id_company/:id_job", component: () => import('../views/Dashboard/FullJob.vue')},
      ],
      meta: {
        requiresAuth: true
      }
    },

    //Empresas
    {
      path: '/Register',
      name: 'Register',
      component: Register
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/PasswordRecovery',
      name: 'PasswordRecovery',
      component: passwordRecovery
    },
    {
      path: '/:id_company', //aqui -> :Empresas (el nombre de la empresa que funciona como ruta dinamica)
      name: 'JobsTree',
      component: JobsTree,
    },
    {
      path: '/:id_company/:id_job', //aqui -> /:company/:id (id es el id del trabajo)
      name: 'singleJob',
      component: singleJob,
    },
    {
      path: '/:id_company/:id_job/apply', //aqui -> /:company/:id/apply (id es el id del trabajo)
      name: 'apply',
      component: Application,
    },
    
    
    //Personas
    {
      path: '/',
      name: 'Home',
      beforeEnter() {
        window.location.href = "https://pill.com.co";
      }
    }
  ]
});


// ++++++++++++++++++++ Middleware antes que se ejecuten las solicitudes de las rutas +++++++++++++++++++++++
router.beforeEach((to,from,next)=> {

  if(to.matched.some(route => route.meta.requiresAuth)){
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {  // Metodo para verificar si hay usuario Logueado y dejalo pasar a la ruta (next())
      if (user) {

        if(user.emailVerified){
          // console.log(user.emailVerified)
          next();
        }else{
          next({
            name: 'login'
          })
        }

      } else {
        next({
          name: 'login'
        })
      }
    });
  }else{
    next()
  }

})

export default router;