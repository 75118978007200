<template>
  <v-app>
    <v-row>
      <v-col>
        <v-row>
          <router-link to="/">
            <v-img class="ml-10 mt-7" src='https://d1kz8c63g8kbpa.cloudfront.net/web/1.png' max-height="100"
              max-width="100" contain></v-img>
            <br>
          </router-link>
        </v-row>
        <div style="padding: 50px 0px 0px" v-if="start || section1 || section2 || section3">
          <v-card class="elevation-0 mx-auto" style="background-color: rgb(255, 255, 255);" max-width="450">
            <h2 v-if="start || section1 || section2 || section3" style="text-align: left; color: black;"
              class="hidden-sm-and-down">Crea tu cuenta de Empresa.</h2>
            <p v-if="start || section1 || section2 || section3" style="font-size: small;" class="hidden-sm-and-down">
              Registra tus datos para crear una cuenta nueva.</p>
            <h2 v-if="start || section1 || section2 || section3" style="text-align: center; color: black;"
              class="hidden-md-and-up">Crea tu cuenta de Empresa.</h2>
            <p v-if="start || section1 || section2 || section3" style="text-align: center; font-size: small;"
              class="hidden-md-and-up">Registra tus datos para crear una cuenta nueva.</p>
            <v-card-text>
              <form ref="form" @submit.prevent="signup()">
                <!-- PRIMERA PARTE DEL REGISTRO -->
                <section v-show="start == true">

                  <p style="color:rgb(24, 24, 24); font-weight: 500;">Email</p>
                  <v-text-field :rules="emailRules" v-model="corporateEmail" style="border-radius: 10px"
                    hint="Ej: user@mycompany.com" type="email" required solo outlined dense
                    color="#7579e7"></v-text-field>

                  <div style="text-align: right;">
                    <v-btn class="white--text" style="text-align: right;" small rounded color="#7579E7"
                      :disabled="!isValidEmail" v-on:click="start = false; section1 = true">Iniciar<v-icon right
                        small>mdi-arrow-right</v-icon>
                    </v-btn>
                  </div>
                </section>
                <section v-show="section1 == true">
                  <p style="color:rgb(24, 24, 24); font-weight: 500;">Contraseña</p>
                  <v-text-field prepend-icon="mdi-lock" v-model="passwordFirst" type="password" required solo outlined
                    dense color="#7579e7" style="border-radius: 10px"></v-text-field>

                  <p style="color:rgb(24, 24, 24); font-weight: 500;">Confirmar Contraseña</p>
                  <v-text-field prepend-icon="mdi-lock-check" v-model="password" type="password" required solo outlined
                    dense color="#7579e7" style="border-radius: 10px"></v-text-field>

                  <p v-show="this.passwordFirst.length < 6 & passwordFirst != ''" style="font-size: small; color: red">❌
                    La contraseña debe tener minimo <strong>6</strong> caracteres</p>
                  <p v-show="this.passwordFirst.length >= 6" style="font-size: small; color: #25D366">✅ La contraseña
                    debe
                    tener minimo <strong>6</strong> caracteres</p>
                  <p v-show="!(this.passwordFirst === this.password)" justify-center
                    style="font-size: small; color: red">
                    ❌ Las contraseñas no son iguales.</p>
                  <p v-show="(this.passwordFirst === this.password & passwordFirst != '')" justify-center
                    style="font-size: small; color: #25D366"> ✅ Las contraseñas son iguales.</p>

                </section>
                <!-- SEGUNDA PARTE DEL REGISTRO -->
                <section v-show="section2 == true">
                  <p style="color:rgb(24, 24, 24); font-weight: 500;">Nombre Empresa</p>
                  <v-text-field v-model="corporateName" type="text" required class="shrink" solo outlined dense
                    color="#7579e7" style="border-radius: 10px"></v-text-field>

                  <p style="color:rgb(24, 24, 24); font-weight: 500;">Industria</p>
                  <v-select v-model="industry" :items="industryListSpanish" small-chips deletable-chips multiple
                    required solo outlined dense color="#7579e7" style="border-radius: 10px"></v-select>

                  <p style="color:rgb(24, 24, 24); font-weight: 500;">País</p>
                  <v-select v-model="country" :items="countryList" required solo outlined dense color="#7579e7"
                    style="border-radius: 10px"></v-select>

                  <p style="color:rgb(24, 24, 24); font-weight: 500;">Website</p>
                  <v-text-field prefix="https://" :rules="websiteRules" v-model="website" name="website URL" type="text"
                    required solo outlined dense color="#7579e7" style="border-radius: 10px"></v-text-field>
                </section>
                <!--TERCERA PARTE DEL REGISTRO-->
                <section v-show="section3 == true">
                  <p style="color:rgb(24, 24, 24); font-weight: 500;">¡Personaliza el link de tú pagina de trabajos!</p>
                  <v-container class="mx-auto rounded-xl" elevation="2">
                    <v-text-field prefix="pill.com.co/" @keydown.space.prevent v-model="username" label="empresa"
                      type="text" required solo outlined dense color="#7579e7"
                      style="border-radius: 10px"></v-text-field>

                    <p v-show="isValidUsername" style="text-align: center; font-size: small; color: #25D366"><v-icon
                        color="#25D366" small left>mdi-check-circle</v-icon> Tu link sera: <b>pill.com.co/{{ username
                        }}</b></p>
                    <p v-show="!isValidUsername" style="text-align: center; font-size: small;"><v-icon small
                        left>mdi-information-outline</v-icon>No uses espacios ni caracteres especiales.</p>
                  </v-container>
                </section>
                <v-row>
                  <v-col sm="15" class="text-left">
                    <v-btn class="mt-4" small rounded color="#7579E7" v-show="section1 == true"
                      v-on:click="start = true; section1 = false" outlined><v-icon left
                        small>mdi-arrow-left</v-icon>Volver</v-btn>
                    <!--Boton de volver 2-->
                    <v-btn class="mt-4" small rounded color="#7579E7" v-show="section2 == true"
                      v-on:click="section1 = true; section2 = false" outlined><v-icon left
                        small>mdi-arrow-left</v-icon>Volver</v-btn>
                    <!--Boton de volver 2-->
                    <v-btn class="mt-4" small rounded color="#7579E7" v-show="section3 == true"
                      v-on:click="section2 = true; section3 = false" outlined><v-icon left
                        small>mdi-arrow-left</v-icon>Volver</v-btn>
                  </v-col>
                  <v-col sm="15" class="text-right">
                    <v-btn class="white--text mt-4" small rounded color="#7579E7" v-show="section1 == true"
                      :disabled="passwordFirst !== password || corporateEmail === '' || passwordFirst === '' || password === ''"
                      v-on:click="section1 = false; section2 = true">Siguiente<v-icon right
                        small>mdi-arrow-right</v-icon></v-btn>
                    <!-- Boton de siguiente 1 -->
                    <v-btn class="white--text mt-4" small rounded color="#7579E7" v-show="section2 == true"
                      :disabled="corporateName === '' || industry === '' || website === '' || country === '' || !isValidWebsite"
                      v-on:click="section2 = false; section3 = true">Siguiente<v-icon right
                        small>mdi-arrow-right</v-icon></v-btn>
                    <v-btn type="submit" class="white--text mt-4" small rounded color="#7579E7" value="Sign up"
                      v-show="section3 == true" :loading="loadingRegister"
                      :disabled="loadingRegister || username === '' || !isValidUsername">Siguiente<v-icon right
                        x-small>mdi-arrow-right</v-icon></v-btn>
                    <!-- Boton de registro completo-->
                  </v-col>
                </v-row>
                <br v-show="!(this.passwordFirst === this.password)">
              </form>
            </v-card-text>
          </v-card>
          <br>
          <center v-if="start || section1 || section2 || section3">
            <p style="font-size: small; text-align: center;" class="mt-4">By joining, you agree to our <a
                href="https://pill.com.co/terms" target="_blank"><u>Terms of Service</u></a>
              and <a href="https://pill.com.co/privacy" target="_blank"><u>Privacy Policy</u></a></p>
            <p style="font-size: small; font-weight: 500; color: black;">¿ya tienes una cuenta? <a
                v-on:click="goToLogin()" style="text-decoration: underline;">Log In</a></p>
          </center>
          <br>
        </div>
        <!--PAYWALL CUARTA PARTE DEL REGISTRO-->
        <section v-show="section4 == true">
          <v-container class="mx-auto rounded-xl" elevation="2">
            <pricing @variable-plan="selectedPlan" />
          </v-container>
        </section>
      </v-col>

      <!--Imagenes Registro-->
      <v-col class="hidden-sm-and-down" style=" text-align: center;" v-if="start || section1 || section2 || section3">
        <v-card style="height: 95%; border-radius: 20px; background-color:#7579e7;" class="ma-5 mb-5">
          <img class="carousel-image" style="padding-top: 120px;" :src="images[currentImage]" alt="Image" width="85%">
        </v-card>
      </v-col>
    </v-row>

  </v-app>
</template>


<script>
/* eslint-disable no-undef */
// Importar modulo "createCompany" AXIOS
import axiosB2B_createCompany from "@/logic/b2b/createCompany/axios"
//Importar modulo backend v2
import jobsBackendV2 from "@/logic/backend_v2/jobsBackend"

import authFirebase from "@/logic/authFirebase";
import emailNotifications from "@/logic/emailNotifications";
import pricing from '@/components/register_components/pricingSection.vue'

// Paddle.Environment.set("sandbox");
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);



// let formData = new FormData();

export default {
  name: "Register-",
  components: {
    pricing
  },
  data: () => ({

    //Imagenes Registro Carrusel
    currentImage: 0,

    images: [
      'https://d1kz8c63g8kbpa.cloudfront.net/web/register-1.png',
      'https://d1kz8c63g8kbpa.cloudfront.net/web/register-2.png',
      'https://d1kz8c63g8kbpa.cloudfront.net/web/register-3.png'
    ],

    //Reglas para Email 
    emailRules: [
      v => !v || /^(?!.*@(gmail\.com|yahoo\.com|hotmail\.com|outlook\.com)$).*$/.test(v) && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'El correo debe ser corporativo, NO debe ser de Gmail, Outlook ó Yahoo.'
    ],

    //Reglas para website
    websiteRules: [
      v => !v || /^(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}(\/)?$/.test(v) || 'Website Invalido'
    ],


    //datos seccion 0 registro
    username: "",
    //datos de seccion 1 registro
    corporateName: "",
    corporateEmail: "",
    passwordFirst: "",
    password: "",

    //datos de seccion 2 registro
    industry: [],
    website: "",
    country: "",
    linkedin: "",
    instagram: "",

    //paises
    countryList: [
      "🇦🇷 Argentina",
      "🇧🇴 Bolivia",
      "🇧🇷 Brasil",
      "🇨🇱 Chile",
      "🇨🇴 Colombia",
      "🇨🇷 Costa Rica",
      "🇪🇨 Ecuador",
      "🇸🇻 El Salvador",
      "🇪🇸 España",
      "🇬🇹 Guatemala",
      "🇭🇳 Honduras",
      "🇳🇮 Nicaragua",
      "🇵🇦 Panamá",
      "🇵🇾 Paraguay",
      "🇵🇪 Perú",
      "🇵🇦 Panamá",
      "🇺🇾 Uruguay",
      "🇻🇪 Venezuela"
    ],

    //Datos de selección 2 sección
    industryListSpanish: ['Tecnología', 'Agricultura', 'Construcción', 'Diseño', 'Ingeniería', 'Finanzas', 'Food', 'Marketing', 'Media', 'Reclutamiento', 'Retail', 'Real State', 'Robotica', 'Hoteleria', 'Inversiones', 'VC', 'Aceleradora', 'Consultoria', 'Otras'],
    industryListEnglish: ['Technology', 'Agriculture', 'Construction', 'Design', 'Engineering', 'Finance', 'Food', 'Marketing', 'Media', 'Recruitment', 'Retail', 'Real State', 'Robotics', 'Hotels', 'Investing', 'VC', 'Accelerator', 'Consulting'],

    //company logo
    urlCompanyLogo: "https://d1kz8c63g8kbpa.cloudfront.net/web/1.png",

    //company colors
    colorpicker: "#7579E7",

    //bandera de cambio de seccion
    start: true,
    section1: false,
    section2: false,
    section3: false,
    section4: false,

    error: false,
    show: false,

    //loader button
    loadingRegister: false,

    url: null,

    paddleCheckoutData: null,

    selectedPlanValue: null

  }),

  async mounted() {
    // Lógica para el carrusel de imágenes
    setInterval(() => {
      this.currentImage = (this.currentImage + 1) % this.images.length;
    }, 2500);

    // Lógica para cargar e inicializar Paddle
    try {
      await this.loadPaddleScript();
      // Paddle.Environment.set("sandbox");
      Paddle.Initialize({
        token: "live_a87ae7dc5433129aab5e2772d27",
        eventCallback: (data) => {
          if (data.name === "checkout.completed") {
            this.checkoutComplete();
          } else if (data.name === "checkout.payment.failed") {
            Vue.$toast.open({
              message: '<p style="font-family: poppins;">Tu pago falló, por favor inténtalo nuevamente!</p>',
              type: 'error',
              position: 'top',
              duration: 10000,
            });
          }
        },
      });
      console.log("Paddle inicializado correctamente.");
    } catch (err) {
      console.error("Error al cargar el script de Paddle:", err);
    }
  },


  computed: {
    isValidEmail() {
      // Expresión regular para validar el correo electrónico y excluir los dominios de Gmail, Yahoo y Hotmail gmail\.com|
      const emailRegex = /^(?!.*@(yahoo\.com|hotmail\.com|outlook\.com)$)\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})$/;
      return emailRegex.test(this.corporateEmail);
    },

    isValidWebsite() {
      // Expresión regular para validar el correo electrónico y excluir los dominios de Gmail, Yahoo y Hotmail
      const websiteRegex = /^(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}(\/)?$/;
      return websiteRegex.test(this.website);
    },

    isValidUsername() {
      // Expresión regular para validar el correo electrónico
      const emailRegex = /^[a-zA-Z0-9]+$/;
      return emailRegex.test(this.username);
    },
  },

  methods: {

    loadPaddleScript() {
      return new Promise((resolve, reject) => {
        if (document.getElementById("paddle-js")) {
          // Script ya cargado
          resolve();
          return;
        }

        const script = document.createElement("script");
        script.id = "paddle-js";
        script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
        script.type = "text/javascript";
        script.async = true;

        script.onload = () => resolve();
        script.onerror = (err) => reject(err);

        document.head.appendChild(script);
      });
    },

    redirectToLogin() {
      this.$router.push("/login")
    },

    checkoutClosed(data) {
      console.log(data);
      Vue.$toast.open({
        message: '<p style="font-family: poppins;">Tu compra fue cancelada porfavor intenta de nuevo.</p>',
        type: 'warning',
        position: 'top',
        duration: 4000,
      });
    },

    async checkoutComplete() {
      console.log("checkout completed", this.selectedPlanValue)

      //Llama a la EndPoint de crack
      if (this.corporateEmail) {
        const resp_payment_user = await jobsBackendV2.updateAccountPlan(this.corporateEmail, String(this.selectedPlanValue))
        console.log(resp_payment_user)
        if (resp_payment_user.data.success == true) {
          // Enviar correo de bienvenida
          await emailNotifications.welcomeCompany(this.corporateName, this.corporateEmail)
          // Enviar correo de NUEVO USUARIO PAGO

          Vue.$toast.open({
            message: '<p style="font-family: poppins;">Suscripción exitosa!</p>',
            type: 'success',
            position: 'top',
            duration: 3000,
          });

        } else {
          Vue.$toast.open({
            message: '<p style="font-family: poppins;">Tu suscripción fallo, porfavor contacta a soporte!.</p>',
            type: 'warning',
            position: 'top',
            duration: 4000,
          });
        }
      }

      //Notification
      let descriptionNotificaction = this.corporateName + " (💸 Paid Plan: " + this.selectedPlanValue + " - " + this.country + " - " + this.industry + ")"
      await emailNotifications.notifyFounder(this.corporateEmail, descriptionNotificaction)

      setTimeout(this.redirectToLogin, 4200);
      console.log('Thanks for your purchase.');
    },

    async completeFree() {

      if (this.corporateEmail) {
        const resp_payment_user = await jobsBackendV2.updateAccountPlan(this.corporateEmail, String(this.selectedPlanValue))
        console.log(resp_payment_user)
        if (resp_payment_user.data.success == true) {
          // Enviar correo de bienvenida
          await emailNotifications.welcomeCompany(this.corporateName, this.corporateEmail)
          Vue.$toast.open({
            message: '<p style="font-family: poppins;">Registro Plan Gratis exitoso!</p>',
            type: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }

        //Notification
        let descriptionNotificaction = this.corporateName + " (🆓 Free " + this.country + " - " + this.industry + ")"
        await emailNotifications.notifyFounder(this.corporateEmail, descriptionNotificaction)

        setTimeout(this.redirectToLogin, 1000);
      },

      selectedPlan(plan) {
        this.selectedPlanValue = plan;

        // Free
        if (this.selectedPlanValue === 0) {
          this.selectedPlanValue = 0;
          console.log("Free Plan");
          this.completeFree()
        }

        //Starter
        if (this.selectedPlanValue === 1) {
          this.selectedPlanValue = 1;
          console.log("Starter Plan", this.corporateEmail);

          Paddle.Checkout.open({
            settings: {
              displayMode: "overlay",
              theme: "light",
              locale: "en"
            },
            items: [{
              priceId: 'pri_01jccpzwgx9qgf3hhh1svx9y9q',
              quantity: 1
            }],
            customer: {
              email: this.corporateEmail,
            }
          });

        }

        //Premium
        else if (this.selectedPlanValue === 2) {
          this.selectedPlanValue = 2;
          console.log("Premium Plan");

          Paddle.Checkout.open({
            settings: {
              displayMode: "overlay",
              theme: "light",
              locale: "en"
            },
            items: [{
              priceId: 'pri_01jccq5trv870thrbb72psfw08',
              quantity: 1
            }],
            customer: {
              email: this.corporateEmail,
            }
          });

        }

        //Starter Anual
        else if (this.selectedPlanValue === 3) {
          this.selectedPlanValue = 3;
          console.log("Starter Plan ANUAL");

          Paddle.Checkout.open({
            settings: {
              displayMode: "overlay",
              theme: "light",
              locale: "en"
            },
            items: [{
              priceId: 'pri_01jccq0nzzwyq5nat2v75zgcmx',
              quantity: 1
            }],
            customer: {
              email: this.corporateEmail,
            }
          });

        }

        //Premium Anual
        else if (this.selectedPlanValue === 4) {
          this.selectedPlanValue = 4;
          console.log("Premium Plan ANUAL");

          Paddle.Checkout.open({
            settings: {
              displayMode: "overlay",
              theme: "light",
              locale: "en"
            },
            items: [{
              priceId: 'pri_01jccq6e8x640xhsj11z0880kh',
              quantity: 1
            }],
            customer: {
              email: this.corporateEmail,
            }
          });

        }

      },

    async signup() {
        this.loadingRegister = true; //activa loader del boton de registro

        // 2. Registrar usuario en firebase
        const responseFirebase = await authFirebase.signup(this.corporateEmail, this.password, this.username.toLowerCase(), this.urlCompanyLogo)
        // 2.1 Condiciones de fallo escritura de contraseña mal, usuario ya registrado
        if (responseFirebase == 'auth/weak-password') {
          //  Contraseña debe contener almenos 6 caracteres
          Vue.$toast.open({
            message: '<p style="font-family: poppins;">¡Tu contraseña debe tener minimo 6 caracteres!</p>',
            type: 'error',
            position: 'top',
            duration: 6000
          });
          this.loadingRegister = false; //desactiva loader del boton de registro
        } else if (responseFirebase == 'auth/email-already-in-use') {  // Condicion usuario ya registrado
          //  "Usuario ya registrado en el sistema"
          Vue.$toast.open({
            message: '<p style="font-family: poppins;">Este usuario ya se encuentra registrado en el sistema.</p>',
            type: 'error',
            position: 'top',
            duration: 0,
          });
          this.loadingRegister = false; //desactiva loader del boton de registro
        } else {
          // 3. Condicion registro EXITOSO!! (LLAMADO A LA API  PARA REGISTRO EN DB) +++++++++++++++++
          const respCreateCompany = await axiosB2B_createCompany.createCompany(this.username.toLowerCase(), this.corporateName, this.corporateEmail, this.industry, this.country, "https://" + this.website, "https://" + this.linkedin, "https://instagram.com/" + this.instagram, this.urlCompanyLogo, this.colorpicker)
          if (respCreateCompany.data.code == "success") {

            this.loadingRegister = false; //desactiva loader del boton de registro
            this.$refs.form.reset(); // clear the free input spaces from the form

            // 5. Seccion de pagos
            this.section3 = false;
            this.section4 = true;

          } else {
            console.log("ERROR -- No se registraron los datos en Mongo!!! ", respCreateCompany)

            // Notificación al usuario DE ERROR!!
            Vue.$toast.open({
              message: '<p style="font-family: poppins;">Oops, ha ocurrido un error intenta de nuevo mas tarde.</p>',
              type: 'success',
              position: 'top',
              duration: 6000
            });
            this.loadingRegister = false; //desactiva loader del boton de registro
          }
        }
      },
    },
  };
</script>

<style>
.v-btn {
  text-transform: none;
}

.carousel-container {
  position: relative;
  overflow: hidden;
}

.carousel-image {
  top: 0;
  left: 0;
}
</style>